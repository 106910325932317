import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import "react-toastify/dist/ReactToastify.css"

type LayoutProps = {
  pageInfo: any;
  children: any;
};

const Layout: FC<LayoutProps> = ({ children }) => {

  const handleAboutClick = () => {
    window.location.href = 'https://sortit.ai#comp-irqduxcp';
  }

  const handleHowItWorksClick = () => {
    window.location.href = 'https://sortit.ai#comp-l93zu0kg1';
  }

  const handleReviewsClick = () => {
    window.location.href = 'https://sortit.ai#comp-l93zu0kh';
  }

  const handleRequestDemoClick = () => {
    window.location.href = 'https://sortit.ai#mediairukrt4y';
  }
  
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <>
          <Navbar bg="dark" variant="dark" expand="lg" className="navbar" collapseOnSelect>
            <Container>
              <Navbar.Brand href="https://sortit.ai">
                <img 
                  src="https://static.wixstatic.com/media/0c2606_d449cce93c7d4ddeba49d298e82de2bb~mv2.jpg/v1/fill/w_200,h_50,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Sort_It_JPG.jpg" 
                  className="d-inline-block align-top" 
                  alt="Sort It logo" 
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-nav" />
              <Navbar.Collapse id="navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link className="nav-link" onClick={handleAboutClick}>About</Nav.Link>
                  <Nav.Link className="nav-link" onClick={handleHowItWorksClick}>How it works</Nav.Link>
                  <Nav.Link className="nav-link" onClick={handleReviewsClick}>Reviews</Nav.Link>
                  <Nav.Link className="nav-link" href="https://sortit.ai/plans-pricing">Plans &amp; Pricing</Nav.Link>
                  <Nav.Link className="nav-link" onClick={handleRequestDemoClick}>Request demo</Nav.Link>
                  <Nav.Link className="nav-link" href="https://sortit.ai/book-online">Book Online</Nav.Link>
                  <Nav.Link className="nav-link" href="https://sortit.ai/blog">Blog</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <Container fluid className="px-0 main" >
            <Row noGutters>
              <Col>
                <main>{children}</main>
              </Col>
            </Row>
          </Container>
          
          <Container fluid className="px-0">
            <Row noGutters>
              <Col className="footer-col">
                <footer>
                  <span>© SORT IT {new Date().getFullYear()}</span>
                </footer>
              </Col>
            </Row>
          </Container>
        </>
      )}
    />
  );
}

export default Layout;